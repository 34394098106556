export const handleDataSentCreateNews = (values, draftStatus) => {
  const dataSent = {
    title: values.title,
    content: values.content,
    news_type: values.type,
    news_status: draftStatus ? 1 : 0,
    schedule: values.time,
  }

  return dataSent
}
